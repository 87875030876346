<template>
  <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
    <!-- Form -->
    <validation-observer ref="callbackRules">
      <b-form class="p-2" @submit.prevent v-if="record">
        <b-row>
          <b-col md="6">
            <b-form-group label="Empresa *">
              <validation-provider
                #default="{ errors }"
                name="Empresa"
                rules="required"
              >
                <vue-autosuggest
                  v-model="queryCompany"
                  :suggestions="companies"
                  :input-props="inputProps"
                  :section-configs="companyConfigs"
                  @input="getCompanies"
                >
                  <template slot-scope="{ suggestion }">
                    <b-badge
                      class="text-capitalize"
                      size="lg"
                      :style="`background: ${suggestion.item.color}`"
                    >
                      {{ getinitials(suggestion.item) }}
                    </b-badge>
                    {{ suggestion.item.name }}
                  </template>
                </vue-autosuggest>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Fila *">
              <validation-provider
                #default="{ errors }"
                name="Fila"
                rules="required"
              >
                <v-select
                  v-if="queues.length > 0"
                  v-model="queuesSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="queues"
                  autocomplete="off"
                  :clearable="false"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Tipo Fila *">
              <validation-provider
                #default="{ errors }"
                name="Tipo Fila"
                rules="required"
              >
                <v-select
                  v-if="types.length > 0"
                  v-model="typesSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  autocomplete="off"
                  :clearable="false"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Hora inicio semana *">
              <validation-provider
                #default="{ errors }"
                name="Hora inicio semana"
                rules="required"
              >
                <b-form-input
                  v-model="record.start_hour_week"
                  placeholder="Hora inicio"
                  type="number"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Hora fim semana *">
              <validation-provider
                #default="{ errors }"
                name="Hora fim semana"
                rules="required"
              >
                <b-form-input
                  v-model="record.end_hour_week"
                  placeholder="Hora fim"
                  type="number"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Hora inicio sabado *">
              <validation-provider
                #default="{ errors }"
                name="Hora inicio sabado"
                rules="required"
              >
                <b-form-input
                  v-model="record.start_hour_saturday"
                  placeholder="Hora inicio"
                  type="number"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Hora fim sabado *">
              <validation-provider
                #default="{ errors }"
                name="Hora fim sabado"
                rules="required"
              >
                <b-form-input
                  v-model="record.end_hour_saturday"
                  placeholder="Hora fim"
                  type="number"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Ativo">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.active"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button variant="info" class="mr-1" @click="validationForm()">
          Salvar
        </b-button>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>
<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import _pabxService from "@/services/pabx-service";
import _companyService from "@/services/company-service";
import _queuesService from "@/services/pabx/queues-service";
import _callbackService from "@/services/callback-service";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  props: {
    dto: {
      type: Object,
      required: true,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isloading: false,
      queryCompany: "",
      isloadingAutocomplete: false,
      debounceMilliseconds: 300,
      inputProps: {
        id: "autocompanies__input_ajax",
        placeholder: "Digite o nome da empresa",
        class: "form-control",
        name: "ajax",
      },
      queues: [],
      queuesSelected: null,
      types: [],
      typesSelected: null,
      companySelected: null,
      companyConfigs: {
        companies: {
          limit: 20,
          label: "Empresas",
          onSelected: (selected) => {
            this.companySelected = selected.item;
          },
        },
      },
      record: {
        id: 0,
        company_id: "",
        active: false,
        queue: "",
        type: 0,
        start_hour_week: 0,
        end_hour_week: 0,
        start_hour_saturday: 0,
        end_hour_saturday: 0,
      },
      companies: [],
    };
  },
  created() {
    this.getInitials();
    this.getQueues();
    this.getTypes();
    localize("pt_BR", pt_br);
  },
  methods: {
    getInitials() {
      this.record = this.dto;

      if (this.dto.company_id) {
        this.companySelected = {
          id: this.dto.company_id,
          name: this.dto.company_name,
        };
        this.queryCompany = this.companySelected.name;
      }
    },
    validationForm() {
      this.$refs.callbackRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getinitials(_item) {
      const ii = _item.name.split(" ");
      if (ii.length >= 2) {
        return ii[0].substring(0, 1) + ii[1].substring(0, 1);
      } else {
        return ii[0].substring(0, 2);
      }
    },
    save() {
      if (this.companySelected) {
        this.record.company_id = this.companySelected.id;
      }

      if (this.queuesSelected) {
        this.record.queue = this.queuesSelected.value;
      }

      if (this.typesSelected) {
        this.record.type = this.typesSelected.value;
      }

      const payload = { data: { ...this.record } };

      const _createOrUpdate =
        this.record.id <= 0
          ? _callbackService.create(payload)
          : _callbackService.update(payload);

      this.isloading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Sucesso", "Atualizado com sucesso.");
          this.$emit("result", { status: "ok" });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getQueues() {
      this.isloading = true;
      _queuesService
        .show(1, "")
        .then((res) => {
          this.queues = this.$utils.populardropdown(
            res.content,
            "queueName",
            "name",
            false,
            false
          );

          if (this.dto.queue) {
            const queue_type = this.queues.filter(
              (f) => f.value === this.dto.queue
            );
            if (queue_type && queue_type.length > 0) {
              this.queuesSelected = queue_type[0];
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getTypes() {
      this.isloading = true;
      _callbackService
        .getTypes()
        .then((res) => {
          this.types = this.$utils.populardropdown(
            res,
            "name",
            "value",
            false,
            false
          );

          const callback_type = this.types.filter(
            (f) => f.value === this.dto.type
          );
          if (callback_type && callback_type.length > 0) {
            this.typesSelected = callback_type[0];
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getCompanies() {
      if (this.queryCompany && this.queryCompany.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.isloading) {
            this.isloading = true;
            _companyService
              .autoComplete(this.queryCompany)
              .then((res) => {
                this.companies = [{ name: "companies", data: res.content }];
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloading = false));
          }
        }, this.debounceMilliseconds);
      }
    },
  },
};
</script>