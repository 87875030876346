<template>
  <b-card>
    <b-row class="mb-1 mb-sm-0">
      <b-col md="6">
        <button--c
          :variant="'gradient-info'"
          :iconsize="'20'"
          :icon="null"
          :permission="'permission.pabx.agents.create'"
          :title="'Cadastrar Callback'"
          @clicked="onClickSave(null)"
          class="mb-2"
        />
      </b-col>
      <b-col md="6">
        <b-input-group>
          <b-form-input
            placeholder="pesquise por Nome, ramal..."
            autocomplete="off"
            v-model="search"
            @keyup.enter="filter"
          />
          <b-input-group-append>
            <b-button variant="gradient-info" @click="filter">
              Pesquisar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
      <b-table
        :items="!isloading ? list[currentePage].itens : []"
        :fields="fields"
        :busy="isloading"
        responsive
        hover
        id="table-lessons"
      >
        <template #cell(active)="data">
          <div class="text-nowrap">
            <b-avatar
              :variant="data.item.active ? 'success' : 'danger'"
              size="25"
            >
              <feather-icon
                size="16"
                :icon="data.item.active ? 'CheckIcon' : 'XIcon'"
              />
            </b-avatar>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`edit-row-${data.item.id}`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="onClickSave(data.item, null)"
            />

            <feather-icon
              :id="`delete-row-${data.item.id}`"
              icon="DeleteIcon"
              size="16"
              class="mx-1"
              @click="confirmDelete(data.item, null)"
              v-if="$utils.isPermited('permission.pabx.agents.delete')"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentePage"
        @change="getLoadMore"
        :total-rows="rows"
        v-if="rows > 20"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-overlay>
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isSave"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isSave">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 v-if="dto.id" class="mb-0">Editar Callback #{{ dto.id }}</h5>
          <h5 v-else class="mb-0">Cadastrar Callback</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <pabx-agent-form @result="onResult" :dto="dto" />
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import _callbackService from "@/services/callback-service";
import pabx_agent from "./callback.vue";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    "pabx-agent-form": pabx_agent,
  },
  data() {
    return {
      debounceMilliseconds: 300,
      timeout: null,
      isloading: false,
      currentePage: 1,
      search: "",
      size: 12,
      rows: 20,
      fields: [
        { key: "companyName", label: "Empresa" },
        { key: "queue", label: "Fila Id" },
        { key: "typeDescription", label: "Tipo" },
        { key: "active", label: "Ativo" },
        { key: "actions", label: "Ações" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      isSave: false,
      dto: null,
    };
  },
  created() {
    this.getRecords(this.currentePage);
  },
  methods: {
    getRecords(_page) {
      this.isloading = true;
      _callbackService
        .show(_page, this.search)
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              this.rows += this.$utils.pagination(res.content, this.size);
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    filter() {
      this.currentePage = 1;
      this.rows = 20;
      this.list = [{ page: 0, itens: [] }];
      this.getRecords(this.currentePage);
    },
    onClickSave(_record) {
      if (_record) {
        // passando dados para outro componente
        this.dto = {
          id: _record.id,
          company_id: _record.company_id,
          active: _record.active,
          queue: _record.queue,
          type: _record.typeId,
          company_name: _record.companyName,
          start_hour_week: _record.start_hour_week,
          end_hour_week: _record.end_hour_week,
          start_hour_saturday: _record.start_hour_saturday,
          end_hour_saturday: _record.end_hour_saturday,
        };
      } else {
        // criar registro novo
        this.dto = {
          id: 0,
          company_id: "",
          active: false,
          queue: "",
          type: 0,
          start_hour_week: 0,
          end_hour_week: 0,
          start_hour_saturday: 0,
          end_hour_saturday: 0,
        };
      }
      this.isSave = true;
    },
    onResult(_res) {
      this.isSave = false;
      this.dto = null;
      this.filter();
    },
    onClickDelete(record, _) {
      this.isloading = true;
      _callbackService
        .delete(record.id)
        .then(() => {
          this.$utils.toast("Sucesso", "Atualizado com sucesso.");
          this.$emit("result", { status: "ok" });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => ((this.isloading = false), this.onResult()));
    },
    confirmDelete(record, _) {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.onClickDelete(record, _);
        }
      });
    },
  },
};
</script>