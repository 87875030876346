import HttpService from './HttpService'

const _http = new HttpService()
export default {
  show: () => {
    return _http.get(`/api/callbackqueue`)
  },
  find: (id) => {
    return _http.get(`/api/callbackqueue/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/callbackqueue', payload)
  },
  update: (payload) => {
    return _http.put('/api/callbackqueue', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/callbackqueue/${id}`)
  },
  getTypes: () => {
    return _http.get(`/api/callbackqueue/types`)
  }
}