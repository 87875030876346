var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":_vm.skin,"show":_vm.isloading,"spinner-variant":"primary","spinner-type":"grow"}},[_c('validation-observer',{ref:"callbackRules"},[(_vm.record)?_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Empresa *"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.companies,"input-props":_vm.inputProps,"section-configs":_vm.companyConfigs},on:{"input":_vm.getCompanies},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('b-badge',{staticClass:"text-capitalize",style:(("background: " + (suggestion.item.color))),attrs:{"size":"lg"}},[_vm._v(" "+_vm._s(_vm.getinitials(suggestion.item))+" ")]),_vm._v(" "+_vm._s(suggestion.item.name)+" ")]}}],null,true),model:{value:(_vm.queryCompany),callback:function ($$v) {_vm.queryCompany=$$v},expression:"queryCompany"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1565228128)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Fila *"}},[_c('validation-provider',{attrs:{"name":"Fila","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.queues.length > 0)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.queues,"autocomplete":"off","clearable":false},model:{value:(_vm.queuesSelected),callback:function ($$v) {_vm.queuesSelected=$$v},expression:"queuesSelected"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,567346826)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo Fila *"}},[_c('validation-provider',{attrs:{"name":"Tipo Fila","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.types.length > 0)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.types,"autocomplete":"off","clearable":false},model:{value:(_vm.typesSelected),callback:function ($$v) {_vm.typesSelected=$$v},expression:"typesSelected"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,509835907)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Hora inicio semana *"}},[_c('validation-provider',{attrs:{"name":"Hora inicio semana","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Hora inicio","type":"number","autocomplete":"off"},model:{value:(_vm.record.start_hour_week),callback:function ($$v) {_vm.$set(_vm.record, "start_hour_week", $$v)},expression:"record.start_hour_week"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3433444372)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Hora fim semana *"}},[_c('validation-provider',{attrs:{"name":"Hora fim semana","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Hora fim","type":"number","autocomplete":"off"},model:{value:(_vm.record.end_hour_week),callback:function ($$v) {_vm.$set(_vm.record, "end_hour_week", $$v)},expression:"record.end_hour_week"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2994453970)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Hora inicio sabado *"}},[_c('validation-provider',{attrs:{"name":"Hora inicio sabado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Hora inicio","type":"number","autocomplete":"off"},model:{value:(_vm.record.start_hour_saturday),callback:function ($$v) {_vm.$set(_vm.record, "start_hour_saturday", $$v)},expression:"record.start_hour_saturday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1406258485)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Hora fim sabado *"}},[_c('validation-provider',{attrs:{"name":"Hora fim sabado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Hora fim","type":"number","autocomplete":"off"},model:{value:(_vm.record.end_hour_saturday),callback:function ($$v) {_vm.$set(_vm.record, "end_hour_saturday", $$v)},expression:"record.end_hour_saturday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4068079763)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Ativo"}},[_c('b-form-checkbox',{staticClass:"custom-control-success mt-1",attrs:{"name":"check-button","switch":""},model:{value:(_vm.record.active),callback:function ($$v) {_vm.$set(_vm.record, "active", $$v)},expression:"record.active"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1)],1)],1),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"info"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" Salvar ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }